import { NewsType } from '@/typedir/types'

export const newsList: NewsType[] = [
  {
    date: '2024.01.25',
    text: '沖縄でスタートアップスタジオ事業やエンジニア教育事業を展開する株式会社Re:Buildを子会社化',
    url: 'https://prtimes.jp/main/html/rd/p/000000240.000016318.html'
  },
  {
    date: '2024.01.15',
    text: '100個の新産業の共創を目指す「新産業のエコシステムビルダー」であるSUNDRED株式会社と資本業務提携',
    url: 'https://prtimes.jp/main/html/rd/p/000000236.000016318.html'
  },
  {
    date: '2023.12.21',
    text: 'Relicホールディングス、グループ全10社の本社を業容拡大に伴い移転',
    url: 'https://prtimes.jp/main/html/rd/p/000000230.000016318.html'
  },
  {
    date: '2023.12.11',
    text: 'Relic、第18回ニッポン新事業創出大賞 アントレプレナー部門において「特別賞」を受賞',
    url: 'https://prtimes.jp/main/html/rd/p/000000223.000016318.html'
  },
  {
    date: '2023.12.07',
    text: 'ＮＴＴデータ社と新規事業の立ち上げから成長・拡大まで一気通貫に支援する業務提携を開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000222.000016318.html'
  },
  {
    date: '2023.12.05',
    text: '業界初の完全成果報酬型エンジニアリングを専門にWebアプリなどの新規ソフトウェア開発を行う「株式会社Pro3Lab」を設立',
    url: 'https://prtimes.jp/main/html/rd/p/000000220.000016318.html'
  },
  {
    date: '2023.10.26',
    text: 'ハードウェアとSaaSを掛け合わせた新規事業開発を支援するソリューション「Hardware SaaS Lab」を提供開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000211.000016318.html'
  },
  {
    date: '2023.09.28',
    text: '東京都のスタートアップ支援展開事業「TOKYO SUTEAM」に採択された学生特化の全方位型スタートアップスタジオ「ZERO1000 Ventures for student」が始動',
    url: 'https://prtimes.jp/main/html/rd/p/000000205.000016318.html'
  },
  {
    date: '2023.09.27',
    text: '先端テクノロジー研究開発専門組織を発足・研究開発によって蓄積されたナレッジを活用し、先端テクノロジーや市場構造変化を起点とした開発支援を行うソリューション「EIL」提供開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000202.000016318.html'
  },
  {
    date: '2023.09.26',
    text: '無人コンビニ「TukTuk」、オフィス向けに冷凍お弁当プランを提供開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000203.000016318.html'
  },
  {
    date: '2023.09.19',
    text: '国内初のトークン化債権のマーケットプレイスの提供を目指す「株式会社3rd Economy」を設立',
    url: 'https://prtimes.jp/main/html/rd/p/000000200.000016318.html'
  },
  {
    date: '2023.05.31',
    text: 'Relicが提供する国内シェアNo.1のイノベーションマネジメント・プラットフォーム『Throttle』にChatGPTを活用した「事業アイデア創出AI」をトライアル実装',
    url: 'https://prtimes.jp/main/html/rd/p/000000174.000016318.html'
  },
  {
    date: '2023.05.23',
    text: 'Relic、新規事業開発の各フェーズにおけるChatGPTの活用方法をまとめたホワイトペーパーを公開',
    url: 'https://prtimes.jp/main/html/rd/p/000000171.000016318.html'
  },
  {
    date: '2023.05.18',
    text: 'Relicホールディングス、スタートアップスタジオ「ZERO1000 Ventures」の第2号案件として、食の流通と購買のイノベーションを実現する「株式会社MEAL FORWARD」を設立',
    url: 'https://prtimes.jp/main/html/rd/p/000000170.000016318.html'
  },
  {
    date: '2023.04.05',
    text: '事業共創カンパニーのRelic、元フューチャーベンチャーキャピタル代表の松本直人氏をCVC支援アドバイザーとして招聘し、CVC支援事業を開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000163.000016318.html'
  },
  {
    date: '2023.03.29',
    text: 'Relic、遠州地方のイノベーション創出に向けた起業家の発掘・育成のため、静岡県浜松市・浜松イノベーションキューブに「Co-Creation Lab HAMAMATSU」を設立',
    url: 'https://prtimes.jp/main/html/rd/p/000000165.000016318.html'
  },
  {
    date: '2023.03.16',
    text: 'Relic、北海道函館市と連携協定を締結し、イノベーター人材の発掘・育成とイノベーション創出に向け、五稜郭フコク生命ビルに「Hakodate Innovation Port」を設立',
    url: 'https://prtimes.jp/main/html/rd/p/000000160.000016318.html'
  },
  {
    date: '2023.03.15',
    text: '株式会社Relicがアジア太平洋地域における急成長企業ランキング2023」に選出されました',
    url: 'https://prtimes.jp/main/html/rd/p/000000161.000016318.html'
  },
  {
    date: '2023.02.15',
    text: 'Relic、遠州地方のイノベーター人材発掘・育成とイノベーション創出に向け、静岡県浜松市・浜松アクトタワーに「Innovation Cross HAMAMATSU」を設立',
    url: 'https://prtimes.jp/main/html/rd/p/000000158.000016318.html'
  },
  {
    date: '2023.01.12',
    text: 'Relicホールディングス、連続的・同時多発的にスタートアップを創出するスタートアップスタジオ「Relic Startup Studio : ZERO1000 Ventures」を始動',
    url: 'https://prtimes.jp/main/html/rd/p/000000140.000016318.html'
  },
  {
    date: '2023.01.10',
    text: 'Relic、東海地方のイノベーター人材発掘・育成とイノベーション創出に向け、愛知県名古屋市・ミッドランドスクエアに「Nagoya Incubation Studio」を設立',
    url: 'https://prtimes.jp/main/html/rd/p/000000144.000016318.html'
  },
  {
    date: '2022.12.20',
    text: '提供を開始したタイムマシン経営型事業アイデア創出サービス 「IDEATION Cloud」が日経クロステックに掲載されました',
    url: 'https://xtech.nikkei.com/atcl/nxt/news/18/14346/'
  },
  {
    date: '2022.12.20',
    text: 'Relic、タイムマシン経営型事業アイデア創出サービス 「IDEATION Cloud」の提供を開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000125.000016318.html'
  },
  {
    date: '2022.12.06',
    text: 'CAMPFIRE ENjiNEがエイベックス・クリエイター・エージェンシーと業務提携し、エンタテインメント業界におけるクラウドファンディングの活用を加速',
    url: 'https://prtimes.jp/main/html/rd/p/000000139.000016318.html'
  },
  {
    date: '2022.11.29',
    text: 'Relic、新規事業のUIUXとブランディングに特化したデザインコンサルティング会社SEESAWに出資し、資本業務提携契約を締結',
    url: 'https://prtimes.jp/main/html/rd/p/000000138.000016318.html'
  },
  {
    date: '2022.10.26',
    text: 'Relic、コミュニティアプリ「Tailor Works」を展開する株式会社テイラーワークスに出資し、資本業務提携契約を締結',
    url: 'https://prtimes.jp/main/html/rd/p/000000131.000016318.html'
  },
  {
    date: '2022.09.01',
    text: '不動産投資型クラウドファンディング構築サービス「ENjiNE」を提供する事業共創カンパニーのRelicが、「クラファンフェスタ powered by Relic 2022」を開催',
    url: 'https://prtimes.jp/main/html/rd/p/000000129.000016318.html'
  },
  {
    date: '2022.08.31',
    text: 'Relic、中国地方のイノベーター人材発掘・育成とイノベーション創出に向け、島根県松江市に「松江イノベーションスクエア」を設立',
    url: 'https://prtimes.jp/main/html/rd/p/000000128.000016318.html'
  },
  {
    date: '2022.08.23',
    text: 'Relicは「PHP Conference Okinawa 2022」にPlatinumスポンサーとして協賛します',
    url: 'https://note.com/relic_tech/n/n7117b2148018'
  },
  {
    date: '2022.08.04',
    text: 'Relic、SaaSに特化したプロダクト共創型エンジニアリング「Digital Innovation Studio for SaaS」を提供開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000124.000016318.html'
  },
  {
    date: '2022.08.02',
    text: 'Relicホールディングスの戦略子会社Scalehackが、Webマーケティングの大衆化を目指すベーシックと業務提携し、BtoBマーケティングを総合的に支援するオールインワンソリューションの提供を開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000126.000016318.html'
  },
  {
    date: '2022.06.30',
    text: 'Relic、AWSパートナーネットワーク（APN）にてセレクトティア認定を取得',
    url: 'https://prtimes.jp/main/html/rd/p/000000117.000016318.html'
  },
  {
    date: '2022.06.20',
    text: 'Relic、コレクタブルNFTを展開するシンガポール発のスタートアップ「W3 Fortune PTE. LTD.」に出資し、資本業務提携契約を締結',
    url: 'https://prtimes.jp/main/html/rd/p/000000118.000016318.html'
  },
  {
    date: '2022.06.02',
    text: 'クラウドファンディングサイト「CAMPFIRE ENjiNE」がオープン！CAMPFIREとENjiNEネットワークの連携開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000116.000016318.html'
  },
  {
    date: '2022.04.18',
    text: 'Relicホールディングスが、事業再生や再成長を支援する「リノベーションスタジオ事業」及び事業のM&A・売買や再配置を支援する「リロケーションスタジオ事業」を展開する戦略子会社「Reboo＋」を設立',
    url: 'https://prtimes.jp/main/html/rd/p/000000113.000016318.html'
  },
  {
    date: '2022.04.05',
    text: '日経ビジネスにて弊社代表・北嶋の連載「VUCA時代を生き抜くための新規事業開発マネジメント」がスタートしました',
    url: 'https://business.nikkei.com/atcl/gen/19/00443/'
  },
  {
    date: '2022.04.05',
    text: 'Relicが日経ビジネス「日本急成長企業2022 売上高を伸ばした100社ランキング」に選出されました',
    url: 'https://business.nikkei.com/atcl/seminar/19/00030/032500343/'
  },
  {
    date: '2022.03.24',
    text: 'Relic、スタートアップスタジオ事業やエンジニア教育事業RUNTEQを展開する株式会社スタートアップテクノロジーに出資し、資本業務提携契約を締結',
    url: 'https://prtimes.jp/main/html/rd/p/000000111.000016318.html'
  },
  {
    date: '2022.03.22',
    text: 'RelicがFinancial Times社「アジア太平洋地域における急成長企業ランキング2022」に選出されました',
    url: 'https://prtimes.jp/main/html/rd/p/000000107.000016318.html'
  },
  {
    date: '2022.03.16',
    text: 'Relic、社会インパクト投資のプラットフォーム構築を目指すインパクトサークル株式会社に出資し、資本業務提携契約を締結',
    url: 'https://prtimes.jp/main/html/rd/p/000000106.000016318.html'
  },
  {
    date: '2022.03.09',
    text: 'Relicホールディングスが、事業の急拡大や持続的成長のためのスケールアップスタジオを展開する戦略子会社「Scalehack」を設立',
    url: 'https://prtimes.jp/main/html/rd/p/000000105.000016318.html'
  },
  {
    date: '2021.01.05',
    text: 'Relicホールディングスが国内最大のクラウドファンディング「CAMPFIRE」との合弁会社「CAMPFIRE ENjiNE」を設立',
    url: 'https://prtimes.jp/main/html/rd/p/000000095.000016318.html'
  },
  {
    date: '2021.12.15',
    text: 'Relicが提供する国内シェアNo.1のSaaS型イノベーションマネジメント・プラットフォーム「Throttle」が『Ruby biz Grand prix 2021』でDX賞を受賞',
    url: 'https://prtimes.jp/main/html/rd/p/000000094.000016318.html'
  },
  {
    date: '2021.11.30',
    text: 'Relic、BtoBスタートアップに特化してPMFやグロースまで成果報酬型や出資を含む多様なスキームで共創する「Growth Partner for Startup」を提供開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000092.000016318.html'
  },
  {
    date: '2021.11.09',
    text: 'Relic、内閣府・経済産業省・ジェトロと海外トップアクセラレーターが提携してグローバル展開を加速する「スタートアップシティ・アクセラレーションプログラム」に採択',
    url: 'https://prtimes.jp/main/html/rd/p/000000091.000016318.html'
  },
  {
    date: '2021.10.28',
    text: 'Relicの国内シェアNo.1イノベーションマネジメント・プラットフォーム「Throttle」が経済産業省「IT導入補助金2021」の対象ツールに採択',
    url: 'https://prtimes.jp/main/html/rd/p/000000089.000016318.html'
  },
  {
    date: '2021.10.20',
    text: 'Relicが和歌山県と進出協定を締結し、史上初となる和歌山市・白浜町の2拠点同時設立により全国展開を加速',
    url: 'https://prtimes.jp/main/html/rd/p/000000088.000016318.html'
  },
  {
    date: '2021.09.28',
    text: 'Relic、電通デジタル社と業務提携し、クラウドファンディングを活用した統合マーケティング支援を提供開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000085.000016318.html'
  },
  {
    date: '2021.09.21',
    text: '株式会社Relicホールディングスの設立及び持株会社体制への移行に関するお知らせ',
    url: 'https://prtimes.jp/main/html/rd/p/000000084.000016318.html'
  },
  {
    date: '2021.09.17',
    text: 'ProductZine「机上のアイデアを価値あるプロダクトへと昇華させる”プロトタイピング”のすすめ」にて、弊社CCO黒木の寄稿記事が掲載されました',
    url: 'https://productzine.jp/article/detail/625'
  },
  {
    date: '2021.09.09',
    text: 'DXマガジン記事「イノベーションは“一発必中”で起こそうとするのではなく、再現性を高める環境づくりに目を向けよ」にて、弊社代表北嶋のインタビューが掲載されました',
    url: 'https://dxmagazine.jp/DX%E3%82%A4%E3%83%B3%E3%82%BF%E3%83%93%E3%83%A5%E3%83%BC/%E5%AE%9F%E8%B7%B5%E8%80%85%E3%82%A4%E3%83%B3%E3%82%BF%E3%83%93%E3%83%A5%E3%83%BC/UoB8i'
  },
  {
    date: '2021.09.08',
    text: 'Relicの国内シェアNo.1クラウドファンディング構築SaaS「CROWDFUNDING NETWORK Powered by ENjiNE」、経済産業省「IT導入補助金2021」の対象ツールに採択',
    url: 'https://prtimes.jp/main/html/rd/p/000000081.000016318.html'
  },
  {
    date: '2021.09.01',
    text: '【書籍出版のお知らせ】弊社取締役CEO 北嶋貴朗 著「イノベーションの再現性を高める新規事業開発マネジメントーー不確実性をコントロールする戦略・組織・実行」',
    url: 'https://prtimes.jp/main/html/rd/p/000000083.000016318.html'
  },
  {
    date: '2021.07.28',
    text: 'Relicが提供する不動産投資のDXと民主化を加速するシェアNo.1のSaaS「不動産投資型クラウドファンディング Powered by ENjiNE」が経済産業省「IT導入補助金2021」対象に採択',
    url: 'https://prtimes.jp/main/html/rd/p/000000079.000016318.html'
  },
  {
    date: '2021.07.15',
    text: 'Fast Grow記事「なぜ“新規事業コンサル“では、事業家が育たないのか？── イノベーション創出を支援するRelicがつくる、新規事業だけに向き合える環境」に弊社取締役大丸、執行役員CGO倉田のインタビューが掲載されました',
    url: 'https://www.fastgrow.jp/articles/relic-daimaru-kurata'
  },
  {
    date: '2021.07.01',
    text: 'プライバシーポリシーを改定しました',
    url: 'https://relic.co.jp/policy/'
  },
  {
    date: '2021.06.15',
    text: 'ProductZine記事「”これだけはやってはいけない”プロダクト開発を失敗に導く2つの悪手と回避策」に弊社取締役大丸のインタビューが掲載されました',
    url: 'https://productzine.jp/article/detail/519'
  },
  {
    date: '2021.06.09',
    text: 'インキュベーションパートナー・プラットフォーム「DUALii」の正式提供開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000073.000016318.html'
  },
  {
    date: '2021.06.01',
    text: 'イノベーションマネジメント・プラットフォーム「Throttle」のCMを放映開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000072.000016318.html'
  },
  {
    date: '2021.05.27',
    text: '現地体験型ツアー専門予約サイト『ベルトラ』が、観光関連サービス特化型クラウドファンディングサイト「Zenes」開設に「ENjiNE」を採用',
    url: 'https://prtimes.jp/main/html/rd/p/000000071.000016318.html'
  },
  {
    date: '2021.04.28',
    text: '森永製菓社の新規事業から生まれたSEE THE SUNが、クラウドファンディングサイト「OUR TeRaSu」開設にRelicが提供する「ENjiNE」を採用',
    url: 'https://prtimes.jp/main/html/rd/p/000000069.000016318.html'
  },
  {
    date: '2021.03.31',
    text: 'Relic、国内シェアNo.1のSaaS型イノベーションマネジメント・プラットフォーム「Throttle」において事業構想や新規事業立案、仮説検証や事業化を支援する新機能をリリース',
    url: 'https://prtimes.jp/main/html/rd/p/000000068.000016318.html'
  },
  {
    date: '2021.02.24',
    text: 'Relicが提供する国内シェアNo.1のイノベーションマネジメント・プラットフォーム『Throttle』とビジネスストラテジーコミュニケーションツール『BizMake』が提携し、共同プランを提供開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000066.000016318.html'
  },
  {
    date: '2021.02.18',
    text: 'FastGrow記事 後編「“事業を創る人を、創る”には“修羅場”が必要──イノベーション創出に特化した、ゼブラ型スタートアップRelic創業者・北嶋貴朗の歩み」にて、弊社代表北嶋の記事が掲載されました',
    url: 'https://www.fastgrow.jp/articles/relic-kitajima-03'
  },
  {
    date: '2021.02.18',
    text: 'FastGrow記事 前編「あえて上場しない──次々と共創型イノベーションを生み出すRelicに学ぶ、縦横無尽のゼブラ型スタートアップ経営」にて、弊社代表北嶋の記事が掲載されました',
    url: 'https://www.fastgrow.jp/articles/relic-kitajima-02'
  },
  {
    date: '2021.02.16',
    text: 'Relic、沖縄でスタートアップスタジオ事業やエンジニア教育事業を展開する株式会社Re:Buildと資本業務提携',
    url: 'https://prtimes.jp/main/html/rd/p/000000065.000016318.html'
  },
  {
    date: '2021.02.05',
    text: 'Biz/Zine連載記事「新規事業開発やイノベーション創出の再現性を高める”インキュベーション戦略”と”IRM”とは？」にて、弊社代表北嶋の記事が掲載されました',
    url: 'https://bizzine.jp/article/detail/5415'
  },
  {
    date: '2021.01.27',
    text: 'Relic、ケイスリー社と包括的業務提携について基本合意',
    url: 'https://prtimes.jp/main/html/rd/p/000000064.000016318.html'
  },
  {
    date: '2021.01.22',
    text: 'Relic、埼玉県川越市からJリーグを目指す「COEDO KAWAGOE F.C」とオフィシャルパートナー契約を締結',
    url: 'https://prtimes.jp/main/html/rd/p/000000063.000016318.html'
  },
  {
    date: '2020.12.22',
    text: 'Relicが国内シェアNo.1のSaaS型イノベーションマネジメント・プラットフォーム「Throttle」のプランを刷新。すべての挑戦者の新規事業創出を支援し、「インキュベーションの民主化」を実現。',
    url: 'https://prtimes.jp/main/html/rd/p/000000061.000016318.html'
  },
  {
    date: '2020.12.16',
    text: 'Relic、東南アジア最大のインフルエンサープラットフォーム「PopStar」を運営するYOYO Holdings社と協業し、ライブ型越境クラウドファンディングサイト「Shopan」を開設',
    url: 'https://prtimes.jp/main/html/rd/p/000000057.000016318.html'
  },
  {
    date: '2020.11.26',
    text: 'Relic、新規事業の成功に不可欠な初期顧客獲得からロイヤルカスタマー化の支援やファンベースマーケティングによるLTV最大化を実現するGrowth Tech「Booster」の一般提供を開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000060.000016318.html'
  },
  {
    date: '2020.11.20',
    text: '事業拡大に伴う福岡支社設立のお知らせ',
    url: 'https://prtimes.jp/main/html/rd/p/000000059.000016318.html'
  },
  {
    date: '2020.11.13',
    text: 'CNET Japan記事「Relic、クラウドファンディングの”今すぐ始めたい”に応えるSaaS型のプラットフォーム「ENjiNE」」にて、弊社安立のインタビューが掲載されました',
    url: 'https://japan.cnet.com/article/35161818/'
  },
  {
    date: '2020.11.05',
    text: 'CreatorZine記事「リモートワークが増えた今だからこそ考えたい これからのデザイナーに必要なコミュニケーションとは」にて、弊社CCO黒木の寄稿記事が掲載されました',
    url: 'https://creatorzine.jp/article/detail/1491'
  },
  {
    date: '2020.10.29',
    text: 'RelicはDX支援で豊富な実績を持つレッドジャーニー社と業務提携し、不確実性の高い新規事業を成功させるための「仮説検証型アジャイル開発」を支援します',
    url: 'https://prtimes.jp/main/html/rd/p/000000056.000016318.html'
  },
  {
    date: '2020.10.14',
    text: '情報セキュリティマネジメントシステム「ISMS（ISO 27001）」認証を取得しました',
    url: 'https://prtimes.jp/main/html/rd/p/000000054.000016318.html'
  },
  {
    date: '2020.09.29',
    text: 'Relic、新規事業の「1→10」フェーズに特化した「Growth Tech」によるマーケティング/営業組織のDXまで、統合的に事業成長を加速するPF「Blitz Square」をリリース',
    url: 'https://prtimes.jp/main/html/rd/p/000000053.000016318.html'
  },
  {
    date: '2020.09.28',
    text: 'Media Innovation記事「ネットワーク型のクラウドファンディング”ENjiNE”で大手メディア企業のコマースを支援するRelic」にて、弊社安立のインタビューが掲載されました',
    url: 'https://media-innovation.jp/2020/09/28/enjine-interview/'
  },
  {
    date: '2020.09.24',
    text: '新規プロダクト/サービス開発における仮説検証をプロトタイピングで支援するサービス「Agile Prototyping Lab（アジャイル プロトタイピング ラボ）」を提供開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000050.000016318.html'
  },
  {
    date: '2020.08.31',
    text: 'Relicが提供するSaaS型クラウドファンディング構築サービス「ENjiNE」の導入数が累計100サイトを突破、導入サイト全体の総流通金額は前年同月比723%を達成',
    url: 'https://prtimes.jp/main/html/rd/p/000000049.000016318.html'
  },
  {
    date: '2020.08.23',
    text: 'グローバルに対応するSaaS型クラウドファンディング・ECサイト構築サービス「ENjiNE」を提供するRelicが、物流プラットフォームのオープンロジと協業',
    url: 'https://prtimes.jp/main/html/rd/p/000000048.000016318.html'
  },
  {
    date: '2020.08.22',
    text: 'CreatorZine記事「新規事業開発の不確実性を”プロトタイピング”で解消 目的に応じた活用方法とは」にて、弊社CCO黒木の寄稿記事が掲載されました',
    url: 'https://creatorzine.jp/article/detail/1237'
  },
  {
    date: '2020.07.29',
    text: 'Relicとb8taがパートナー契約を締結。クラウドファンディングとb8ta店舗の対象区画を融合し、あらゆるユーザー行動をデータ化するOMOテストマーケティングサービスを提供開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000046.000016318.html'
  },
  {
    date: '2020.07.14',
    text: 'RelicのSaaS型イノベーションマネジメント・プラットフォーム『Throttle』がグローバル対応を開始し、オンラインで世界規模の新規事業開発やイノベーション創出を実現へ',
    url: 'https://prtimes.jp/main/html/rd/p/000000045.000016318.html'
  },
  {
    date: '2020.06.23',
    text: '事業拡大に伴う大阪支社設立のお知らせ',
    url: 'https://prtimes.jp/main/html/rd/p/000000041.000016318.html'
  },
  {
    date: '2020.06.15',
    text: 'CreatorZine記事「リモート環境でも再現性の高いプロダクト開発を Relicデザインチームの取り組み」にて、弊社CCO黒木の寄稿記事が掲載されました',
    url: 'https://creatorzine.jp/article/detail/1071'
  },
  {
    date: '2020.06.11',
    text: 'SalesZine記事「新規事業を拡大/成長フェーズに導く営業とは？分業せず少数精鋭のチームでKPIを持つRelicに学ぶ」にて、弊社事業部長倉田のインタビューが掲載されました',
    url: 'https://saleszine.jp/article/detail/1503'
  },
  {
    date: '2020.06.10',
    text: 'Relicが提供するSaaS型クラウドファンディング構築サービス「ENjiNE」が金融型クラウドファンディングへの対応第2弾として融資型/ソーシャルレンディング型の本格提供を開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000039.000016318.html'
  },
  {
    date: '2020.06.02',
    text: '＠IT記事「おしえて、キラキラお兄さん：新規事業開発ってむなしくないですか？」にて、弊社CTO大庭のインタビューが掲載されました',
    url: 'https://www.atmarkit.co.jp/ait/articles/2006/02/news018.html'
  },
  {
    date: '2020.05.25',
    text: 'Biz/Zine連載記事「新規事業開発に挑み続けるための“撤退基準”の定め方──成功に向けた“健全な多産多死”を実現するには」にて、弊社代表北嶋の記事が掲載されました',
    url: 'https://bizzine.jp/article/detail/4647'
  },
  {
    date: '2020.04.08',
    text: '企業のイノベーティブな新規事業創出を加速　Relicとアスタミューゼが業務提携',
    url: 'https://prtimes.jp/main/html/rd/p/000000037.000016318.html'
  },
  {
    date: '2020.04.06',
    text: 'Relicとプレジデント社が協業し、“記事と連動する” クラウドファンディングサイト「PRESIDENT×dancyuクラウドファンディング」を開設しました',
    url: 'https://prtimes.jp/main/html/rd/p/000000035.000016318.html'
  },
  {
    date: '2020.04.02',
    text: '株式会社Relic、国内最大級のクラウドファンディング・ソーシャルレンディング専門メディア「クラウドポートニュース」事業をファンズ株式会社より譲受',
    url: 'https://prtimes.jp/main/html/rd/p/000000036.000016318.html'
  },
  {
    date: '2020.03.31',
    text: 'CNET記事「Relic、クラウドファンディング構築サービス”ENjiNE”で不動産投資型も開始」にて弊社サービスが紹介されました',
    url: 'https://japan.cnet.com/article/35151570/'
  },
  {
    date: '2020.03.30',
    text: 'Relicが提供するSaaS型クラウドファンディング構築サービス「ENjiNE」が金融型クラウドファンディングへの対応を開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000033.000016318.html'
  },
  {
    date: '2020.03.23',
    text: 'Relicが提供するSaaS型イノベーションマネジメント・プラットフォーム『Throttle』の導入企業数が1,000社を突破し、大幅な新機能追加とアップデートを実施',
    url: 'https://prtimes.jp/main/html/rd/p/000000034.000016318.html'
  },
  {
    date: '2020.03.10',
    text: 'CNET記事「新規事業開発を加速する"Throttle"の実力–Relicが"インキュベーションテック"で描く未来」にて弊社代表北嶋',
    url: 'https://japan.cnet.com/article/35149130/'
  },
  {
    date: '2020.03.10',
    text: '事業開発に特化した共創型エンジニアリングサービス「Digital Innovation Studio」を開始しました',
    url: 'https://prtimes.jp/main/html/rd/p/000000032.000016318.html'
  },
  {
    date: '2020.03.05',
    text: 'CNET記事「企業内に数%しかいないイノベーターをどう育てるか–Relicが定義する育成手法"IRM"」にて弊社代表北嶋',
    url: 'https://japan.cnet.com/article/35147481/'
  },
  {
    date: '2020.02.25',
    text: 'CNET記事「新規事業は”インキュベーション戦略”で勝敗が決まる-Relic北嶋氏、大丸氏に聞く方程式」にてインタビューが掲載されました',
    url: 'https://japan.cnet.com/article/35145926/'
  },
  {
    date: '2020.02.10',
    text: 'JBpress記事「大企業の新規事業開発、上手くいかない３つの理由”とりあえずやってみる”の乱発の先に待つ悲劇」にて、弊社代表北嶋のインタビューが掲載されました',
    url: 'https://jbpress.ismedia.jp/articles/-/59152'
  },
  {
    date: '2020.02.07',
    text: '業容拡大につきオフィスを移転いたしました。移転先住所：〒150-6008 東京都渋谷区恵比寿4-20-3 恵比寿ガーデンプレイスタワー8F',
    url: 'https://relic.co.jp/info/20423/'
  },
  {
    date: '2020.02.03',
    text: 'Relicが提供するSaaS型クラウドファンディング構築サービス「ENjiNE」を活用した日本経済新聞社の「未来ショッピング」、人気プロジェクトの展示販売をブックファースト新宿店で開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000029.000016318.html'
  },
  {
    date: '2020.01.16',
    text: 'ITmedia ビジネスオンライン記事「クラウドファンディングをマーケティングに活用　”募金目的”から一皮剥けたワケ」にて、弊社COO大丸のインタビューが掲載されました',
    url: 'https://www.itmedia.co.jp/business/articles/2001/16/news069.html'
  },
  {
    date: '2020.01.16',
    text: 'アジア地域No.1規模の日本紹介メディアを運営するFun Japan Communicationsと協業し、越境購入型クラウドファンディングサイトを開設',
    url: 'https://prtimes.jp/main/html/rd/p/000000028.000016318.html'
  },
  {
    date: '2019.11.26',
    text: 'Relicが提供するSaaS型イノベーションマネジメント・プラットフォーム『Throttle』、リリース後3ヵ月で導入企業数500社/利用者数3万人を突破',
    url: 'https://prtimes.jp/main/html/rd/p/000000023.000016318.html'
  },
  {
    date: '2019.11.22',
    text: 'Biz/Zine記事「無消費を起点とした「市場創造型イノベーション」──大企業で“説明責任の呪縛”を回避し実践するには？」にて、弊社代表北嶋とINDEE Japan津田真吾氏の対談記事が掲載されました',
    url: 'https://bizzine.jp/article/detail/3936'
  },
  {
    date: '2019.11.20',
    text: '日経ビジネス電子版にて、Relicの「ENjiNE」を採用したパナソニックのクラウドファンディング型マーケティングプラットフォーム「TAMATEBA」の記事が掲載されました',
    url: 'https://business.nikkei.com/atcl/NBD/19/depth/00421/'
  },
  {
    date: '2019.11.18',
    text: 'パナソニックコンシューマーマーケティングがクラウドファンディング型マーケティングプラットフォーム「TAMATEBA」開設にRelicの「ENjiNE」を採用',
    url: 'https://prtimes.jp/main/html/rd/p/000000021.000016318.html'
  },
  {
    date: '2019.11.01',
    text: 'Biz/Zine連載記事「新規事業開発の成否を分ける“エグゼキューション能力”──事業を推進するためのKPI設定・可視化・改善」にて、弊社代表北嶋の記事が掲載されました',
    url: 'https://bizzine.jp/article/detail/3999'
  },
  {
    date: '2019.10.01',
    text: '読売新聞、Relicの提供するSaaS型クラウドファンディング構築プラットフォーム「CROWDFUNDING NETWORK Powered by ENjiNE」を導入',
    url: 'https://prtimes.jp/main/html/rd/p/000000019.000016318.html'
  },
  {
    date: '2019.09.09',
    text: 'FASTGLOW記事「"スタートアップの真似事が大企業の進化を止める" DeNA出身のRelic北嶋氏が提唱する、大企業に必要な新規事業開発のメソッドとは？」にて、弊社代表北嶋のインタビューが掲載されました',
    url: 'https://www.fastgrow.jp/articles/relic-kitajima'
  },
  {
    date: '2019.08.29',
    text: '株式会社Relic、新規事業開発・イノベーション創出に特化した国内初のSaaS型イノベーションマネジメント・プラットフォーム「Throttle(スロットル)」をリリース',
    url: 'https://prtimes.jp/main/html/rd/p/000000018.000016318.html'
  },
  {
    date: '2019.07.16',
    text: 'Wantedly記事「”CCO”って、どんなポジション？Relicが「クリエイティブ」を重視する理由。」にて、弊社CCO黒木のインタビューが掲載されました',
    url: 'https://www.wantedly.com/companies/relic/post_articles/162001'
  },
  {
    date: '2019.06.28',
    text: '大阪大学情報科学研究科「イノベーション論」の講義にて弊社代表の北嶋が講師として登壇しました',
    url: 'https://relic.co.jp/info/18867/'
  },
  {
    date: '2019.06.20',
    text: 'Biz/Zine連載記事「新規事業開発において事業プランやアイデアよりも大切なこと──適切な評価と検証に必要なアプローチとは？」にて、弊社代表北嶋の記事が掲載されました',
    url: 'https://bizzine.jp/article/detail/3614'
  },
  {
    date: '2019.05.22',
    text: 'Wantedly記事「事業ドメインは「新規事業」。"指名"が絶えないビジネスマンでありたいCOO」にて、弊社COO大丸のインタビューが掲載されました',
    url: 'https://www.wantedly.com/companies/relic/post_articles/160157'
  },
  {
    date: '2019.04.11',
    text: 'Wantedly記事「経営陣にエンジニアがいるからできること。若きCTOが挑む"会社作り"」にて、弊社CTO大庭のインタビューが掲載されました',
    url: 'https://www.wantedly.com/companies/relic/post_articles/161979'
  },
  {
    date: '2019.03.13',
    text: 'Biz/Zine連載記事「新規事業のアイデア・プラン創出を成功させるアプローチ 事業開発の現場で頻出する”3つの課題”とは？」にて、弊社代表北嶋の記事が掲載されました',
    url: 'https://bizzine.jp/article/detail/3418'
  },
  {
    date: '2019.01.20',
    text: '新規事業・イノベーション特化型メディア「Battery」を公開しました',
    url: 'https://relic.co.jp/battery/'
  },
  {
    date: '2018.12.18',
    text: 'Biz/Zine記事「新規事業開発の成功率を高める組織作り――新規事業のために検討すべき3つのステップとは？」にて、弊社代表北嶋の記事が掲載されました',
    url: 'https://bizzine.jp/article/detail/3247'
  },
  {
    date: '2018.12.14',
    text: 'CNET記事「仮説検証こそが新規事業を成功に導く重要なファクター–Relicの北嶋氏」にて、弊社代表のインタビューが掲載されました',
    url: 'https://japan.cnet.com/article/35130157/'
  },
  {
    date: '2018.11.19',
    text: '次世代型マーケティングオートメーションサービス「Booster」のサービス詳細ページを公開しました',
    url: 'https://relic.co.jp/services/booster/'
  },
  {
    date: '2018.11.14',
    text: '株式会社fluct、提携メディアに弊社サービス「CROWDFUNDING NETWORK Powered by ENjiNE」の導入支援を開始しました',
    url: 'https://corp.fluct.jp/pressrelease/fluct/'
  },
  {
    date: '2018.10.05',
    text: '日本経済新聞社記事「Relic、クラウドファンディング参入支援 」にて弊社サービス「CROWDFUNDING NETWORK Powered by ENjiNE」が紹介されました',
    url: 'https://www.nikkei.com/article/DGXMZO36163910V01C18A0000000/'
  },
  {
    date: '2018.10.04',
    text: 'CNET記事「Relic、SaaS型クラウドファンディング構築プラットフォーム–月額固定費無料」にて弊社サービスが紹介されました',
    url: 'https://japan.cnet.com/article/35126563/'
  },
  {
    date: '2018.10.04',
    text: 'TECHABLE記事「初期費用＆月額固定費無料の成果報酬型クラウドファンディング構築プラットフォームの本格提供が開始」にて弊社サービスが紹介されました',
    url: 'https://techable.jp/archives/84656'
  },
  {
    date: '2018.10.04',
    text: '初期費用/月額固定費無料のネットワーク型クラウドファンディング構築プラットフォーム「CROWDFUNDING NETWORK Powered by ENjiNE」を本格提供開始しました',
    url: 'https://prtimes.jp/main/html/rd/p/000000012.000016318.html'
  },
  {
    date: '2017.09.28',
    text: '弊社代表の北嶋が「オープンイノベーションが拓く新ビジネス創出セミナー2017」にて講演いたしました',
    url: 'http://www.campuscreate.com/open-innovation/'
  },
  {
    date: '2017.09.26',
    text: '弊社代表の北嶋のインタビュー記事が「DREAM GATE」に掲載されました',
    url: 'http://www.dreamgate.gr.jp/venture_secret/5961'
  },
  {
    date: '2017.09.01',
    text: '弊社代表の北嶋が「Books&Apps」にて「小中学校でのプログラミング必修化にはどのような意義があるのか。」の記事を寄稿いたしました',
    url: 'http://blog.tinect.jp/?p=42270'
  },
  {
    date: '2017.07.27',
    text: '弊社代表の北嶋が「Books&Apps」にて「社内の特殊な才能をもつ人材を、どうやって発掘し、起業家に育てるか」の記事を寄稿いたしました',
    url: 'http://blog.tinect.jp/?p=41339'
  },
  {
    date: '2017.05.26',
    text: '弊社代表の北嶋がNTTドコモの新規事業創出プログラム「39works」で、「新規事業開発における適切なKGI/KPIの設計/運用を通じた事業マネジメント」のテーマで講演いたしました',
    url: 'https://www.39works.net/'
  },
  {
    date: '2017.02.23',
    text: '弊社代表の北嶋が「Books&Apps」にて「「胸の大きな女性のための通勤服」を、テストマーケティングした時の話。」の記事を寄稿いたしました',
    url: 'http://blog.tinect.jp/?p=37081'
  },
  {
    date: '2017.02.01',
    text: '弊社代表の北嶋が「近代中小企業」2017年1月号に「中小企業の新規事業開発におけるオープンイノベーション」について寄稿いたしました',
    url: 'http://www.datadeta.co.jp/01-kinchu/201701.pdf'
  },
  {
    date: '2017.01.25',
    text: '弊社代表の北嶋が「Books&Apps」にて「AmazonやGoogle、リクルートなどが行っているように、社内から良い新事業アイデアを集める方法とは。」の記事を寄稿いたしました',
    url: 'http://blog.tinect.jp/?p=34970'
  },
  {
    date: '2017.01.17',
    text: '弊社代表の北嶋が「Books&Apps」にて「売上・利益やKPIなどの数字だけを見て、新規事業の撤退を決めてしまうのは愚策。本当に大事なことは…」の記事を寄稿いたしました',
    url: 'http://blog.tinect.jp/?p=34562'
  },
  {
    date: '2016.12.21',
    text: '弊社代表の北嶋が「Books&Apps」にて「「技術者派遣」と「受託開発」がメインだったシステム開発会社の新規事業が成功した理由とは」の記事を寄稿いたしました',
    url: 'http://blog.tinect.jp/?p=33750'
  },
  {
    date: '2016.11.24',
    text: '弊社代表の北嶋が「Books&Apps」にて「チャレンジしてもそれほど報われないのに、リスクだけは大きい社内ベンチャー制度を、どう変えたら良いか？」の記事を寄稿いたしました',
    url: 'http://blog.tinect.jp/?p=33169'
  },
  {
    date: '2016.11.24',
    text: '株式会社Relicが日本経済新聞社、新東通信社と事業パートナー契約を締結して購入型クラウドファンディングサービス「未来ショッピング Powered by ENjiNE」を開始しました',
    url: 'http://prtimes.jp/main/html/rd/p/000000011.000016318.html'
  },
  {
    date: '2016.11.17',
    text: '弊社代表の北嶋が「Books&Apps」にて「流行りの「オープンイノベーション」というキーワード。理想と現実を語ります。」の記事を寄稿いたしました',
    url: 'http://blog.tinect.jp/?p=32762'
  },
  {
    date: '2016.11.01',
    text: '弊社代表の北嶋が「Books&Apps」にて「なぜ大企業において「優秀な人」と「潤沢な資金」があっても、新規事業の立ち上げはうまくいかないのか。」の記事を寄稿いたしました',
    url: 'http://blog.tinect.jp/?p=32192'
  },
  {
    date: '2016.03.08',
    text: 'クラウドファンディング「ENjiNE」が「Lien PROJECT2016」最終審査にて、日本初の「ライブファンディング」を実施。運営システムの無料提供もスタート',
    url: 'http://prtimes.jp/main/html/rd/p/000000006.000016318.html'
  },
  {
    date: '2016.02.09',
    text: 'TechCrunch 記事 "「チケット化」と「継続販売」で需要を喚起するクラウドファンディング「ENjiNE」"にて弊社サービスが紹介されました',
    url: 'https://prtimes.jp/main/html/rd/p/000000006.000016318.html'
  }
]
